<template>
  <div style="margin-bottom: 300px">
    <v-row class="text-center mt-2">
      <v-col cols="12">
        <v-row>
          <v-col cols="2" md="4"> </v-col>
          <v-col cols="8" md="4">
            <h1 style="color: white; border: solid 1px">คำสั่งซื้อของฉัน</h1>
          </v-col>
        </v-row>
        <v-card
          class="mx-auto pa-8 mt-4"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-row v-if="!orderThai.length">
            <v-col cols="12">
              <v-card class="gradient-button pa-4 text-center">
                <span style="font-size: 20px"
                  >ท่านยังไม่มี <br />
                  รายการสั่งซื้อ</span
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" v-if="lotteryDate.length">
              <span style="font-size: 20px">เลือกงวด</span>
              <v-select
                v-model="selectedDate"
                :items="lotteryDate"
                item-text="date"
                item-value="lotteryDate"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
              v-for="(lotto, index) in itemsLottery"
              :key="index"
            >
              <v-card
                style="font-size: 16px; border: 1px solid #4ca3ad"
                class="py-4 px-4 mb-4"
                :search="search"
              >
                <div
                  class="my-4 py-2 text-center"
                  style="padding: 5px; border: 3px solid #4ca3ad"
                >
                  สถานะ

                  <span v-if="lotto.status == 'PENDING'">
                    <span style="color: #647d87">รอการชำระเงิน</span>
                  </span>
                  <span v-if="lotto.status == 'รอดำเนินการ'">
                    <span style="color: #008080">รอดำเนินการ</span>
                  </span>
                  <span v-if="lotto.status == 'SUCCESS'">
                    <span style="color: green">อนุมัติ/ยืนยันสลิปถูกต้อง</span>
                  </span>
                  <span v-if="lotto.status == 'มีปัญหา'">
                    <span style="color: red">มีปัญหา</span>
                  </span>
                  <span v-if="lotto.status == 'ยกเลิก'">
                    <span style="color: red">ยกเลิก</span>
                  </span>
                </div>
                <div class="mb-2 ml-4">
                  <p style="color: #008080; font-weight: 800; font-size: 18px">
                    หมายเลขคำสั่งซื้อ
                  </p>
                  <p>{{ lotto.transactionNo }}</p>
                </div>
                <div class="mb-2 ml-4">
                  <p style="color: #008080; font-weight: 800; font-size: 18px">
                    ประจำงวดที่
                  </p>
                  <p>{{ convertDateLottery(lotto.lotteryDate) }}</p>
                </div>
                <div class="mb-2 ml-4">
                  <p style="color: #008080; font-weight: 800; font-size: 18px">
                    วัน - เวลา ที่สั่งซื้อ
                  </p>
                  <p>{{ convertDate(lotto.createdAt) }}</p>
                </div>
                <div class="mb-2 ml-4">
                  <p style="color: #008080; font-weight: 800; font-size: 18px">
                    จำนวนใบที่สั่งซื้อ
                  </p>
                  <p>{{ lotto.lotteryTransaction.length }} ใบ</p>
                </div>
                <div class="mb-4 ml-4">
                  <p style="color: #008080; font-weight: 800; font-size: 18px">
                    ราคารวม
                  </p>
                  <p>{{ lotto.totalPrice }} บาท</p>
                </div>
                <div>
                  <v-btn
                    large
                    block
                    style="
                      background: linear-gradient(#4ca3ad 0%, #008080 100%);
                    "
                    @click="tranxDetail(lotto.id)"
                  >
                    <span style="color: white">รายละเอียด</span>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import moment from "moment";

export default {
  components: {},

  name: "BuyLottery",

  data: () => ({
    dialogLogin: false,
    selectedItems: [],
    itemsLotteryAll: [],

    search: "",
    datefrom: "",

    hidden: false,
    tabs: null,

    userData: [],
    orderThai: [],

    selectedDate: null,
    lotteryDate: [],
    itemsLottery: [],
  }),

  watch: {
    selectedDate(val) {
      const filterDate = this.orderThai.filter((x) => x.lotteryDate == val);
      this.itemsLottery = filterDate;
      console.log("val", val);
      console.log("this.itemsLottery", this.itemsLottery);
    },
  },

  async created() {
    const encodedUserLogin = localStorage.getItem("LEKSUAYMILLOINUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }

    await this.getlotterythai();
  },

  methods: {
    convertDate(date) {
      return moment(String(date)).format("DD/MM/YYYY HH:mm");
    },
    convertDateLottery(date) {
      return moment(String(date)).format("DD/MM/YYYY");
    },
    async getlotterythai() {
      const auth = {
        headers: { Authorization: `Bearer ${this.userData.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAllByUser/` +
          this.userData.user.id,
        auth
      );
      let getlotto = response.data.data.filter((x) => x.status !== "SUCCESS");
      this.orderThai = getlotto;
      this.findDuplicateLotteryDate(getlotto);
    },
    async findDuplicateLotteryDate(getlotto) {
      const newArray = getlotto.filter((item, index, arr) => {
        const firstIndex = arr.findIndex(
          (el) => el.lotteryDate === item.lotteryDate
        );
        return index === firstIndex;
      });
      console.log(newArray);

      newArray.forEach((element) => {
        const dateObj = new Date(element.lotteryDate);
        const thaiDate = new Intl.DateTimeFormat("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(dateObj);
        this.lotteryDate.push({
          date: thaiDate,
          lotteryDate: element.lotteryDate,
        });
      });
      this.selectedDate = this.lotteryDate[0].lotteryDate;
    },
    tranxDetail(val) {
      localStorage.setItem("transactionDetail", Encode.encode(val));
      this.$router.push("/transactionDetail");
    },
  },
};
</script>

<style scoped>
.gradient-button {
  background: linear-gradient(#4ca3ad 0%, #008080 100%);
  color: white;
}
</style>

